import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants'
import get from 'lodash/get'
import { Disqus } from 'gatsby-plugin-disqus'
import { RichText, Date } from 'prismic-reactjs'
import moment from 'moment'

import { rhythm, scale } from '../utils/typography'
import dateFormat from '../utils/date-format'
import Layout from '../components/layout'
import { NonStretchedImage } from '../components/ui/image'
import { linkResolver } from '../utils/link-resolver'
import htmlSerializer from '../utils/html-serializer'

const PostContent = styled.div`
  .gatsby-resp-image-wrapper {
    margin-left: ${rhythm(1)}!important;
    border: 1px solid black;
    width: 100%;
    border-radius: 5px;

    ${MOBILE_MEDIA_QUERY} {
      margin-left: 0 !important;
    }

    img {
      border-radius: 5px;
    }
  }
  
  img {
      width: 100%;
  }
`

const PostImg = styled(NonStretchedImage)``

export default function NewsPostTemplate({
  data,
  location,
  pageContext: { lang },
}) {
  const {
    title,
    date,
    imageSharp,
    content,
    _meta: { uid },
  } = get(data, 'prismic.allBlogposts.edges[0].node')
  //const post = data.markdownRemark
  // const { previous, next } = pageContext
  const imageFluid = get(imageSharp, 'childImageSharp.fluid')
  moment.locale(lang)
  const siteUrl = get(data, 'site.siteMetadata.siteUrl')
  const siteDescription = get(data, 'site.siteMetadata.description')

  let disqusConfig = {
    url: `${siteUrl + uid}`,
    identifier: uid,
    title: RichText.asText(title),
  }

  return (
    <Layout
      title={RichText.asText(title)}
      subtitle={'Blog'}
      site={data.site}
    >
      <h1 style={{ marginTop: 0, marginBottom: rhythm(1) }}>
        {RichText.asText(title)}
      </h1>
      <p
        style={{
          ...scale(-1 / 5),
          display: `block`,
          marginBottom: rhythm(1),
          marginTop: rhythm(-1),
        }}
      >
        {moment(Date(date)).format(dateFormat(lang))}
      </p>
      <PostContent>
        <RichText
          render={content}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializer}
        />
      </PostContent>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />

      {/*<ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>*/}
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <Disqus config={disqusConfig} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($uid: String) {
    site {
      ...SiteInformation
    }
    prismic {
      allBlogposts(uid: $uid) {
        edges {
          node {
            title
            image
            content
            date
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                  presentationHeight
                }
              }
            }
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`
